import React from 'react';
import '../../App.css';
import Section from '../Section.js';
import Card from '../Card.js';
import Footer from '../Footer.js';

function Home(){
    return(
        <>
        <Section />
        <Card />
        <Footer />
        </>
    );
}

export default Home;