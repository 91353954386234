import React from 'react';
import './TheStory.css';
import BgVideo from '../videos/package.mp4';

const Story = () => {
    return (
        <><video autoplay='autoplay' loop width={300} height={200} >
        <source src={BgVideo} type="video/mp4"/>
      </video>
      
        <div className='story_container'>
            <div className='story_content'>
                <h1>日星：像日光星光永遠保護</h1>
                <br />
                <p>西元1989年9月，日星紙器成立於台北市士林區洲美，創始人林妏憶女士，傅進益先生成為董事並成立現在的日星紙器。
                </p>
                <p>傅先生及林女士運用自身的專業經歷及管理知識對廠內進行改革整治，完善廠內動線及員工分配，並完成起死回身的創舉，最北至基隆最南至台中（舊時有至高雄）皆有長期合作客戶。
                </p>
                <p>於西元2000年6月遷廠至台北縣蘆洲市（今新北市蘆洲區），2008年後經歷金融海嘯及國際通縮的困境，日星紙器仍然堅強挺過難關，持續深耕台灣至今已35年（~2024），繼續生產高品質產品給親愛的客戶。
                </p>
                <br />
                <h2>真材實料，進守本份</h2>
                <p>1989年9月，一家紙器公司迎來了新的生命。那一年，日星紙器在困境中誕生。我們的創始人決心以「真材實料」的核心價值和「進守本份」的經營理念，重新定義紙器行業的標準。我們相信，只有堅持品質，才能贏得信任，創造長久的價值。</p>
                <br />
                <h2>一路走來的堅持與成長</h2>
                <p>在創立初期，日星紙器面臨著重重挑戰，資金短缺，設備老舊。然而，我們始終堅信，只要不懈努力，並堅持我們的核心價值，終將贏得客戶的信賴。我們不斷改進工藝，引進先進設備，並且每一個細節都不放過，確保產品質量無懈可擊。我們一步步前行，這背後是無數個不眠之夜和不懈的努力。</p>
                <br />
                <h2>廣泛的客群，無限的可能</h2>
                <p>日星紙器的產品廣泛適用於各行各業，無論是大型企業還是個人消費者，只要有需要紙箱的地方，就有我們的身影。我們深知，每一個客戶的需求都是獨特的，因此我們提供定制化服務，確保每一個紙箱都能完美符合客戶的需求。我們的產品不僅僅是包裝，更是品質與信任的象徵。</p>
                <br />
                <h2>彩色禮盒，展現獨特風采</h2>
                <p>除了傳統的紙箱，我們還專門製作彩色禮盒。我們根據客戶提供的模板進行生產，確保每一個禮盒都能夠精確地滿足客戶的設計需求。這些精美的禮盒不僅僅是一個包裝，更是傳遞心意與情感的載體。每一個禮盒都凝聚了我們的用心和專業，旨在讓每一份禮物都顯得更加獨特和珍貴。</p>
                <br />
                <h2>未來的路，我們一起走</h2>
                <p>展望未來，日星紙器將繼續堅持「真材實料，不偷工減料」的核心價值，為更多的客戶提供高品質的紙器產品。我們相信，只有不斷追求卓越，才能在競爭激烈的市場中立於不敗之地。我們期待與您一起，攜手共創更加美好的未來，讓日星紙器成為您信賴的合作伙伴。</p>
                <br />
            </div>
        </div>
        </>
    );
}

export default Story;
