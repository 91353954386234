import React from "react";
import '../../App.css';
import PrivacyPolicy from "../Privacypolicy.js";
import FooterAlter from '../Footeralter.js';

export default function Services(){
    return (
        <>
        <br/>
        <PrivacyPolicy/>
        <br/>
        <FooterAlter/>
        </>        
    );

}