import React from 'react';
import './ServicesTerm.css';

const PrivacyPolicy = () => {
    return (
        <div className='services_container'>
            <div className='content'>
                <h1>隱私權保護政策</h1>
                <br />
                <p>日星紙器有限公司（以下簡稱本公司），本公司絕對尊重並保護用戶的個人隱私權，為了協助您清楚地瞭解本服務如何蒐集、
                    應用及保護您所提供的個人資料，請您詳細閱讀本服務隱私權保護政策。</p>
                <br />
                <h2>適用範圍</h2>
                <p>本隱私權保護政策適用於您在使用本服務過程中，涉及個人資料蒐集、處理、利用與保護之相關作法。</p>
                <br />
                <h2>蒐集的資料類型、蒐集目的及使用方式</h2>
                <p>當您於本服務時，我們會詢問您的姓名、電子郵件地址等資料。您可以自由選擇是否提供個人資料給我們，
                    但如特定資料欄位係屬必填者，您如不提供該等資料則無法使用相關服務。</p>
                <br />
                <h2>個人資料分享政策</h2>
                <p>本公司不會與第三人分享您的個人資料。</p>
                <br />
                <h2>個人資料使用期間與地區</h2>
                <p>本服務僅在中華民國境內利用您的個人資料。您在本服務所輸入的個人資料，除於服務項目網頁上另有公告者，將因應前述蒐集目的永久保存；惟公司對超過5年未登入本服務之會員帳號或其它因服務維運作業需要，擁有刪除資料之權利。</p>
                <br />


                <h2>隱私權保護政策修訂</h2>
                <p>本隱私權保護政策將不定期修訂。本服務不會在未經您明確同意的情況下，縮減您的權利。隱私權保護政策變更時一律於本服務發布；如屬於重大變更者，本服務將搭配主動通知機制，讓您了解本隱私權保護政策所作之變更。</p>
                <br />
                <h2>與我們聯絡</h2>
                <p>若您對本服務隱私權保護政策或個人資料行使權利有任何疑問，請與客服中心聯繫，聯絡方式如下:</p>
                <br />
            </div>
        </div>
    )
}

export default PrivacyPolicy;
