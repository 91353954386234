import React from 'react';
import './LineSocial.css';

const LineSocial = () => {
  return (
    <div className='line_container'>
      <img src='images/sunstarcarton.jpg' alt='line_social' className='line_img'/>
      <h2>掃描加入日星紙器社群</h2>
    </div>
  );
}

export default LineSocial;
